import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { BehaviorSubject } from 'rxjs';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class GoogleMapService {
  private apiKey: string = environment.google_map_key;
  map: google.maps.Map | undefined;
  marker: google.maps.Marker | undefined;
  geocoder: google.maps.Geocoder | undefined;
  selectedLocation: BehaviorSubject<google.maps.LatLngLiteral | null> = new BehaviorSubject<google.maps.LatLngLiteral | null>(null);

  constructor() {}

  async loadMap(containerId: string, center: google.maps.LatLngLiteral, zoom: number): Promise<void> {
    const loader = new Loader({
      apiKey: this.apiKey,
      libraries: ['places'],
    });

    const google = await loader.load();
    this.map = new google.maps.Map(document.getElementById(containerId)!, {
      center,
      zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
    });
    this.geocoder = new google.maps.Geocoder();
    this.marker = new google.maps.Marker({map: this.map});
  }

  updateMarker(location: google.maps.LatLngLiteral) {
    if (this.marker && this.map) {
      this.marker.setPosition(location);
      this.map.panTo(location);
      if (this.map.getZoom()! < 16) {
        this.map.setZoom(16);
      }
      this.selectedLocation.next(location);
    }
  }


  setAutocomplete(inputElement: HTMLInputElement, onPlaceChange?: (autocomplete?: google.maps.places.Autocomplete) => void) {
    const autocomplete = new google.maps.places.Autocomplete(inputElement, {
      fields: ['formatted_address', 'geometry'],
      types: ['(cities)'],
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) return;
      const location = place.geometry.location?.toJSON();
      if (location) this.updateMarker(location);
      onPlaceChange && onPlaceChange(autocomplete)
    });
  }

  geocodeLocation(location: google.maps.LatLngLiteral): Promise<string | null> {
    return this.geocoder
      ?.geocode({ location })
      .then((result) => (result?.results?.[0]?.formatted_address) || null) || Promise.resolve(null);
  }
}
