import { Injectable } from '@angular/core';
import { eventNameEnum } from "../enums/event.enum";
import { EventType } from "@enums";

@Injectable({
  providedIn: 'root',
})
export class EventMapperService {
  private readonly eventMap: { [key: string]: { eventType: EventType, description: string } } = {
    [eventNameEnum.SUBJECTS_SCREEN_VISIT]: { eventType: EventType.PAGE_VIEW, description: 'Viewing subjects screen' },
    [eventNameEnum.SUBJECTS_SEARCH_STARTED]: { eventType: EventType.BUTTON_CLICK, description: 'Started searching for subjects' },
    [eventNameEnum.SUBJECTS_SEARCH_DONE]: { eventType: EventType.BUTTON_CLICK, description: 'Subjects search completed' },
    [eventNameEnum.SUBJECT_NOT_FOUND]: { eventType: EventType.PAGE_VIEW, description: 'Subject not found' },
    [eventNameEnum.SUBJECT_SELECTED]: { eventType: EventType.SELECT_VALUE, description: 'Selected a subject' },
    [eventNameEnum.SUB_LEVELS_SCREEN_VISIT]: { eventType: EventType.PAGE_VIEW, description: 'Viewing sub-levels screen' },
    [eventNameEnum.SUB_LEVEL_SELECTED]: { eventType: EventType.SELECT_VALUE, description: 'Selected a sub-level' },
    [eventNameEnum.GRADES_POPUP]: { eventType: EventType.POPUP_VIEW, description: 'Viewing grades pop-up' },
    [eventNameEnum.GRADE_SELECTED]: { eventType: EventType.SELECT_VALUE, description: 'Selected a grade' },
    [eventNameEnum.CURRICULUM_SCREEN_VISIT]: { eventType: EventType.PAGE_VIEW, description: 'Viewing curriculum screen' },
    [eventNameEnum.CURRICULUM_SELECTED]: { eventType: EventType.SELECT_VALUE, description: 'Selected a curriculum' },
    [eventNameEnum.PREFERENCE_SCREEN_VISIT]: { eventType: EventType.PAGE_VIEW, description: 'Viewing preference screen' },
    [eventNameEnum.PREFERENCE_SELECTED]: { eventType: EventType.SELECT_VALUE, description: 'Selected a preference' },
    [eventNameEnum.BACK_BUTTON_CLICKED]: { eventType: EventType.GO_BACK, description: 'Clicked back button' },
    [eventNameEnum.LOOK_FOR_TUTOR_BUTTON_CLICKED]: { eventType: EventType.BUTTON_CLICK, description: 'Clicked look for tutor button' },
    [eventNameEnum.LOADER_SCREEN_VISIT]: { eventType: EventType.PAGE_VIEW, description: 'Viewing loader screen' },
    [eventNameEnum.LOADER_SCREEN_FINISH]: { eventType: EventType.PAGE_VIEW, description: 'Loader screen completed' },
    [eventNameEnum.SUGGESTED_TUTOR_PROFILE]: { eventType: EventType.PAGE_VIEW, description: 'Viewing suggested tutor profile' },
    [eventNameEnum.EXPLORE_OTHER_TUTORS_BUTTON_CLICKED]: { eventType: EventType.BUTTON_CLICK, description: 'Clicked explore other tutors button' },
    [eventNameEnum.OTHER_TUTORS_POPUP]: { eventType: EventType.POPUP_VIEW, description: 'Viewing other tutors pop-up' },
    [eventNameEnum.BOOK_THIS_TUTOR]: { eventType: EventType.BUTTON_CLICK, description: 'Clicked book this tutor' },
    [eventNameEnum.NO_TUTOR_FOUND]: { eventType: EventType.PAGE_VIEW, description: 'No tutor found screen' },
    [eventNameEnum.COMPLETE_WITH_NO_TUTOR]: { eventType: EventType.PAGE_VIEW, description: 'Completed without tutor' },
    [eventNameEnum.SHARE_YOUR_LOCATION_BUTTON_CLICKED]: { eventType: EventType.BUTTON_CLICK, description: 'Clicked share location button' },
    [eventNameEnum.LOCATION_PERMISSION_POPUP]: { eventType: EventType.POPUP_VIEW, description: 'Viewing location permission pop-up' },
    [eventNameEnum.LOCATION_PERMISSION_APPROVED]: { eventType: EventType.PERMISSION_APPROVED, description: 'Approved location permission' },
    [eventNameEnum.ADD_LOCATION_MANUALLY]: { eventType: EventType.BUTTON_CLICK, description: 'Clicked add location manually' },
    [eventNameEnum.MAP_SCREEN]: { eventType: EventType.PAGE_VIEW, description: 'Viewing map screen' },
    [eventNameEnum.BROWSER_LOCATION_PERMISSION_POPUP]: { eventType: EventType.POPUP_VIEW, description: 'Viewing browser location pop-up' },
    [eventNameEnum.LOCATION_CONFIRMED]: { eventType: EventType.BUTTON_CLICK, description: 'Location confirmed' },
    [eventNameEnum.SIGN_UP_SCREEN_VISIT]: { eventType: EventType.PAGE_VIEW, description: 'Viewing sign-up screen' },
    [eventNameEnum.SIGN_UP_SCREEN_SHORT_FLOW_VISIT]: { eventType: EventType.PAGE_VIEW, description: 'Viewing short sign-up flow' },
    [eventNameEnum.FORM_SUBMIT]: { eventType: EventType.BUTTON_CLICK, description: 'Form submitted' },
    [eventNameEnum.GO_TO_PAYMENT]: { eventType: EventType.BUTTON_CLICK, description: 'Clicked go to payment' },
    [eventNameEnum.NORMAL_THANK_YOU_PAGE]: { eventType: EventType.PAGE_VIEW, description: 'Viewing thank-you page' },
    [eventNameEnum.PACKAGES_SCREEN_ONLINE_OFFLINE_VISIT]: { eventType: EventType.PAGE_VIEW, description: 'Viewing online/offline packages' },
    [eventNameEnum.PACKAGES_SCREEN_ONLINE_VISIT]: { eventType: EventType.PAGE_VIEW, description: 'Viewing online packages' },
    [eventNameEnum.SESSION_TYPE_BUTTON_CLICKED]: { eventType: EventType.BUTTON_CLICK, description: 'Clicked session type button' },
    [eventNameEnum.CONFIRM_PACKAGE]: { eventType: EventType.BUTTON_CLICK, description: 'Confirmed package' },
    [eventNameEnum.CHECKOUT_SCREEN_VISIT]: { eventType: EventType.PAGE_VIEW, description: 'Viewing checkout screen' },
    [eventNameEnum.CHECKOUT_SCREEN_PAYMENT_BUTTON_CLICKED]: { eventType: EventType.BUTTON_CLICK, description: 'Clicked checkout payment button' },
    [eventNameEnum.COMPLETE_SELF_CHECKOUT_PAYMENT]: { eventType: EventType.PAGE_VIEW, description: 'Completed self-checkout payment' },
    [eventNameEnum.SELF_CHECKOUT_PAYMENT_FAILED]: { eventType: EventType.PAGE_VIEW, description: 'Self-checkout payment failed' },
    [eventNameEnum.PAYMENT_THANK_YOU_SCREEN]: { eventType: EventType.PAGE_VIEW, description: 'Viewing payment thank-you screen' },
    [eventNameEnum.SIGN_UP_FLOW_PACKAGES_SCREEN_V2_ONLINE_OFFLINE]: { eventType: EventType.PAGE_VIEW, description: 'Viewing v2 online/offline packages' },
    [eventNameEnum.SIGN_UP_FLOW_PACKAGES_SCREEN_V2_ONLINE]: { eventType: EventType.PAGE_VIEW, description: 'Viewing v2 online packages' },
    [eventNameEnum.SIGN_UP_FLOW_PACKAGES_SCREEN_V2_SESSION_TYPE_BUTTON]: { eventType: EventType.BUTTON_CLICK, description: 'Clicked v2 session type button' },
    [eventNameEnum.SIGN_UP_FLOW_CONFIRM_PACKAGE_V2]: { eventType: EventType.BUTTON_CLICK, description: 'Confirmed package v2' }
  };

  mapEvent(eventName: string): { eventType: EventType; description: string; status?: string } {
    const { eventType, description } = this.eventMap[eventName];
    return { eventType, description };
  }
}
