export enum EventType {
  BUTTON_CLICK = 'Button Click',
  INCOMING_HTTP_REQUEST = 'Incoming HTTP Req',
  PAGE_VIEW = 'Page View',
  OUTGOING_HTTP_REQUEST = 'Outgoing HTTP Req',
  SELECT_VALUE = 'Selected Value',
  GO_BACK = 'Go Back',
  POPUP_VIEW = 'Popup View',
  PERMISSION_APPROVED = 'Permission Approval',
}
