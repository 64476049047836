export enum eventEnum {
  PAGE_VISIT = `PAGE_VISIT`,
}

export const SIGNUP_FLOW_EVENT = 'Sign up flow';

export enum eventNameEnum {
  SUBJECTS_SCREEN_VISIT = `${SIGNUP_FLOW_EVENT} - subjects screen`,
  SUBJECTS_SEARCH_STARTED = `${SIGNUP_FLOW_EVENT} - subjects search started`,
  SUBJECTS_SEARCH_DONE = `${SIGNUP_FLOW_EVENT} - subjects search done`,
  SUBJECT_NOT_FOUND = `${SIGNUP_FLOW_EVENT} - subject is not found`,
  SUBJECT_SELECTED = `${SIGNUP_FLOW_EVENT} - subject selected`,
  SUB_LEVELS_SCREEN_VISIT = `${SIGNUP_FLOW_EVENT} - sub-levels screen`,
  SUB_LEVEL_SELECTED = `${SIGNUP_FLOW_EVENT} - sub-level selected`,
  GRADES_POPUP = `${SIGNUP_FLOW_EVENT} - grades pop-up`,
  GRADE_SELECTED = `${SIGNUP_FLOW_EVENT} - grade selected`,
  CURRICULUM_SCREEN_VISIT = `${SIGNUP_FLOW_EVENT} - curriculum screen`,
  CURRICULUM_SELECTED = `${SIGNUP_FLOW_EVENT} - curriculum selected`,
  PREFERENCE_SCREEN_VISIT = `${SIGNUP_FLOW_EVENT} - preference screen`,
  PREFERENCE_SELECTED = `${SIGNUP_FLOW_EVENT} - preference selected`,
  BACK_BUTTON_CLICKED = `${SIGNUP_FLOW_EVENT} - back button`,
  LOOK_FOR_TUTOR_BUTTON_CLICKED = `${SIGNUP_FLOW_EVENT} - look for a tutor button`,
  LOADER_SCREEN_VISIT = `${SIGNUP_FLOW_EVENT} - loader screen`,
  LOADER_SCREEN_FINISH = `${SIGNUP_FLOW_EVENT} - loader finished`,
  SUGGESTED_TUTOR_PROFILE = `${SIGNUP_FLOW_EVENT} - suggested tutor profile`,
  EXPLORE_OTHER_TUTORS_BUTTON_CLICKED = `${SIGNUP_FLOW_EVENT} - explore other tutors button`,
  OTHER_TUTORS_POPUP = `${SIGNUP_FLOW_EVENT} - other tutors pop-up`,
  BOOK_THIS_TUTOR = `${SIGNUP_FLOW_EVENT} - book this tutor`,
  NO_TUTOR_FOUND = `${SIGNUP_FLOW_EVENT} - no tutor found`,
  COMPLETE_WITH_NO_TUTOR = `${SIGNUP_FLOW_EVENT} - complete with no tutor`,
  SHARE_YOUR_LOCATION_BUTTON_CLICKED = `${SIGNUP_FLOW_EVENT} - share your location button`,
  LOCATION_PERMISSION_POPUP = `${SIGNUP_FLOW_EVENT} - location permission pop-up`,
  LOCATION_PERMISSION_APPROVED = `${SIGNUP_FLOW_EVENT} - location permission approved`,
  ADD_LOCATION_MANUALLY = `${SIGNUP_FLOW_EVENT} - add location manually`,
  MAP_SCREEN = `${SIGNUP_FLOW_EVENT} - map screen`,
  BROWSER_LOCATION_PERMISSION_POPUP = `${SIGNUP_FLOW_EVENT} - browser location permission pop-up`,
  LOCATION_CONFIRMED = `${SIGNUP_FLOW_EVENT} - location confirmed`,
  SIGN_UP_SCREEN_VISIT = `${SIGNUP_FLOW_EVENT} - sign up screen`,
  SIGN_UP_SCREEN_SHORT_FLOW_VISIT = `${SIGNUP_FLOW_EVENT} - sign up screen (short flow)`,
  FORM_SUBMIT = `${SIGNUP_FLOW_EVENT} - Form Submit`,
  GO_TO_PAYMENT = `${SIGNUP_FLOW_EVENT} - go to payment`,
  NORMAL_THANK_YOU_PAGE = `${SIGNUP_FLOW_EVENT} - normal thank you page`,
  PACKAGES_SCREEN_ONLINE_OFFLINE_VISIT = `${SIGNUP_FLOW_EVENT} - packages screen (online/offline)`,
  PACKAGES_SCREEN_ONLINE_VISIT = `${SIGNUP_FLOW_EVENT} - packages screen (online)`,
  SESSION_TYPE_BUTTON_CLICKED = `${SIGNUP_FLOW_EVENT} - packages screen - session type button`,
  CONFIRM_PACKAGE = `${SIGNUP_FLOW_EVENT} - confirm package`,
  CHECKOUT_SCREEN_VISIT = `${SIGNUP_FLOW_EVENT} - checkout screen`,
  CHECKOUT_SCREEN_PAYMENT_BUTTON_CLICKED = `${SIGNUP_FLOW_EVENT} - checkout screen payment button`,
  COMPLETE_SELF_CHECKOUT_PAYMENT = `${SIGNUP_FLOW_EVENT} - complete self-checkout payment`,
  SELF_CHECKOUT_PAYMENT_FAILED = `${SIGNUP_FLOW_EVENT} - self-checkout payment faild`,
  PAYMENT_THANK_YOU_SCREEN = `${SIGNUP_FLOW_EVENT} - payment thank you screen`,
  SIGN_UP_FLOW_PACKAGES_SCREEN_V2_ONLINE_OFFLINE =`${SIGNUP_FLOW_EVENT} - packages screen v2 (online/offline)`,
  SIGN_UP_FLOW_PACKAGES_SCREEN_V2_ONLINE =`${SIGNUP_FLOW_EVENT} - packages screen v2 (online)`,
  SIGN_UP_FLOW_PACKAGES_SCREEN_V2_SESSION_TYPE_BUTTON =`${SIGNUP_FLOW_EVENT} - packages screen v2 - session type button`,
  SIGN_UP_FLOW_CONFIRM_PACKAGE_V2 = `${SIGNUP_FLOW_EVENT} - confirm package v2`
}
