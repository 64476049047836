import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionService } from '@services';
import { EventMapperService } from "../../features/signupflow/services/event-mapper.service";
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from "../../../environments/environment";
import { UtilityService } from "../../shared/services/utility.service";

interface LogData {
  eventType: string;
  eventSource: string;
  sessionId: string;
  payload: object;
  description: string;
  status: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private eventMapperService: EventMapperService,
    private utilityService: UtilityService,
  ) {}

  logEvent(payload: any): void {
    const logData = this.createLogData(payload.eventType, payload.properties, payload.description, payload.status);
    if (this.utilityService.isBrowser) this.sendLogToAPI(logData);
  }

  logEventForSegmentFlow(eventName: string, properties: object): void {
    const { eventType, description, status } = this.eventMapperService.mapEvent(eventName);
    const logData = this.createLogData(eventType, properties, description, status || 'success');
    if (this.utilityService.isBrowser) this.sendLogToAPI(logData);
  }

  private createLogData(eventType: string, payload: object, description: string, status: string): LogData {
    return {
      eventType,
      eventSource: 'Frontend',
      sessionId: this.sessionService.getSessionId(),
      payload,
      description,
      status,
    };
  }

  private sendLogToAPI(logData: LogData): void {
    this.http.post(`${environment.mainUrl}/tracking/api/logs`, logData, { params: { noLoading: 'true' }})
      .pipe(
        catchError(() => {
          return of(null);
        })
      )
      .subscribe();
  }
}
