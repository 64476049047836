import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class SessionService {

  private readonly sessionIdKey: string = 'sessionId';

  constructor() {}

  getSessionId(): string {
    let sessionId = sessionStorage.getItem(this.sessionIdKey);
    if (!sessionId) {
      sessionId = this.generateSessionId();
      sessionStorage.setItem(this.sessionIdKey, sessionId);
    }
    return sessionId;
  }

  private generateSessionId(): string {
    return uuidv4();
  }
}
