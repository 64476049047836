import { Mapper } from '@utils';
import {PackageModel} from '@models';
import {inject, Injectable} from '@angular/core';
import {PackageEntity} from "../entities";
import {getValueByLang} from "@helpers";
import {TranslateFacadeService} from "@services";

@Injectable({
  providedIn: 'root',
})
export class PackageImplRepoMapper extends Mapper<
  PackageEntity[],
  PackageModel[]
> {
  translateFacade: TranslateFacadeService = inject(TranslateFacadeService);

  mapFrom(param: PackageEntity[]): PackageModel[] {
    return param.map((packageData) => {
      return {
        id: packageData.id,
        name: getValueByLang({ar: packageData.package_name_ar, en: packageData.package_name_en, currentLanguage: this.translateFacade.currLang}) as string,
        event_name: packageData.package_name_en,
        hours: packageData.package_hours,
        price: packageData.package_price,
        old_price: packageData.package_old_price,
        status: packageData.package_status,
        discount: packageData.discount,
        vat: packageData.vat_type,
        length_unit: packageData.package_length_unit,
        type: packageData.type,
        isPreferred: packageData?.isPreferred ?? false,
        isCheapest: packageData?.isCheapest ?? false,
      }
    })
  }
}
