import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  getCurrentLocation(
    onPermissionGranted?: (position: GeolocationPosition) => void,
    onPermissionDenied?: (error: GeolocationPositionError) => void
  ): Promise<GeolocationPosition | null> {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject('ALGooru GeoLocation API not supported -- Muayyad');
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (onPermissionGranted) {
              onPermissionGranted(position);
            }
            resolve(position);
          },
          (error) => {
            if (onPermissionDenied) {
              onPermissionDenied(error);
            }
            reject(error);
          }
        );
      }
    });
  }
}
