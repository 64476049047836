import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptInjectService {

  injectScript(src: string, async: boolean = true, defer: boolean = false, innerHtml?: boolean): void {
    const script = document.createElement('script');
    script.async = async;
    script.defer = defer;
    if (innerHtml) {
      script.innerHTML = src;
    } else {
      script.src = src;
    }
    document.body.appendChild(script);
  }


  injectWistiaScript() {
    this.injectScript('https://fast.wistia.com/assets/external/E-v1.js');
  }

  appendStyle(name: string) {
    let style = document.createElement('link');
    style.rel = 'stylesheet';
    style.type = 'text/css';
    style.href = name;
    document.head.appendChild(style);
  }

  injectInitialScripts() {
    // this.injectScript('../assets/load-js-files/message-bird.script.js');
    this.injectScript('../assets/load-js-files/segment.script.js');

  }

}
