export let pk:any ={
  'university':{
    "data": [
      {
        // "id": "50ea74eb-3083-4472-94d7-3a598b5f17e9", live
        "id": "ce42caab-7429-41f5-b8a3-e2153207b776",

        "package_name_en": "8 hrs",
        "package_name_ar": "٨ ساعات",
        "package_hours": 8,
        "package_hours_per_package_length_unit": 8,
        "package_price": 120,
        "package_old_price": 0,
        "package_status": "online",
        "type": "Package",
        "educational_level": "School",
        "package_length": 1,
        "discount": 0,
        "internal_name": "SC 8 hrs online",
        "upfront_payment_discount": 0,
        "package_vat": 0.15,
        "vat_type": "SAUDI_VAT",
        "created_at": "2024-07-05T09:22:25.000000Z",
        "is_recurrent": false,
        "installment_template_id": null,
        "package_length_unit": "month"
      },
      {
        // "id": "b5d9ecfb-b7e2-4ff1-b60b-176efca2ac2d", live
        "id": "c29d9ac1-14fa-4e6c-9aaf-460448bad553",

        "package_name_en": "12 hrs",
        "package_name_ar": "١٢ ساعة",
        "package_hours": 12,
        "package_hours_per_package_length_unit": 12,
        "package_price": 120,
        "package_old_price": 0,
        "package_status": "online",
        "type": "Package",
        "educational_level": "School",
        "package_length": 1,
        "discount": 0,
        "internal_name": "SC 12 hrs online",
        "upfront_payment_discount": 0,
        "package_vat": 0.15,
        "vat_type": "SAUDI_VAT",
        "created_at": "2024-07-05T09:22:25.000000Z",
        "is_recurrent": false,
        "installment_template_id": null,
        "package_length_unit": "month",
        "isPreferred": true
      },
      {
        // "id": "30824f27-a1d7-45eb-8737-fb876a177d52", live
        "id": "10b2368c-30b1-4091-b079-b0bc01c98b5c",

        "package_name_en": "16 hrs",
        "package_name_ar": "١٦ ساعة",
        "package_hours": 16,
        "package_hours_per_package_length_unit": 16,
        "package_price": 120,
        "package_old_price": 0,
        "package_status": "online",
        "type": "Package",
        "educational_level": "School",
        "package_length": 1,
        "discount": 0,
        "internal_name": "SC 16 hrs online",
        "upfront_payment_discount": 0,
        "package_vat": 0.15,
        "vat_type": "SAUDI_VAT",
        "created_at": "2024-07-05T09:22:25.000000Z",
        "is_recurrent": false,
        "installment_template_id": null,
        "package_length_unit": "month",
      },
      {
        // "id": "45f0be04-fb59-4ae5-8446-668449f1133b", live
        "id": "d406d685-13da-471c-b122-dddecb32802f",

        "package_name_en": "20 hrs",
        "package_name_ar": "٢٠ ساعة",
        "package_hours": 20,
        "package_hours_per_package_length_unit": 20,
        "package_price": 120,
        "package_old_price": 0,
        "package_status": "online",
        "type": "Package",
        "educational_level": "School",
        "package_length": 1,
        "discount": 0,
        "internal_name": "SC 20 hrs online",
        "upfront_payment_discount": 0,
        "package_vat": 0.15,
        "vat_type": "SAUDI_VAT",
        "created_at": "2024-07-05T09:22:25.000000Z",
        "is_recurrent": false,
        "installment_template_id": null,
        "package_length_unit": "month",
        "isCheapest": true
      }
    ],
    "message": "Packages returned successfully",
    "code": 200
  },
  'online':{
    "data": [
      {
        "id": "f9c6eb81-d65f-4c40-b6fc-7ed135876a2e",
        "package_name_en": "8 hrs",
        "package_name_ar": "٨ ساعات",
        "package_hours": 8,
        "package_hours_per_package_length_unit": 8,
        "package_price": 80,
        "package_old_price": 0,
        "package_status": "online",
        "type": "Package",
        "educational_level": "School",
        "package_length": 1,
        "discount": 0,
        "internal_name": "SC 8 hrs online",
        "upfront_payment_discount": 0,
        "package_vat": 0.15,
        "vat_type": "SAUDI_VAT",
        "created_at": "2024-07-05T09:22:25.000000Z",
        "is_recurrent": false,
        "installment_template_id": null,
        "package_length_unit": "month"
      },
      {
        "id": "b9438649-ca5b-4b43-a5c1-a3f8df139e84",
        "package_name_en": "12 hrs",
        "package_name_ar": "١٢ ساعة",
        "package_hours": 12,
        "package_hours_per_package_length_unit": 12,
        "package_price": 76,
        "package_old_price": 80,
        "package_status": "online",
        "type": "Package",
        "educational_level": "School",
        "package_length": 1,
        "discount": 5,
        "internal_name": "SC 12 hrs online",
        "upfront_payment_discount": 0,
        "package_vat": 0.15,
        "vat_type": "SAUDI_VAT",
        "created_at": "2024-07-05T09:22:25.000000Z",
        "is_recurrent": false,
        "installment_template_id": null,
        "package_length_unit": "month",
        "isPreferred": true
      },
      {
        "id": "9308058e-32a6-44af-840b-8ebc3e1997a1",
        "package_name_en": "16 hrs",
        "package_name_ar": "١٦ ساعة",
        "package_hours": 16,
        "package_hours_per_package_length_unit": 16,
        "package_price": 72,
        "package_old_price": 76,
        "package_status": "online",
        "type": "Package",
        "educational_level": "School",
        "package_length": 1,
        "discount": 10,
        "internal_name": "SC 16 hrs online",
        "upfront_payment_discount": 0,
        "package_vat": 0.15,
        "vat_type": "SAUDI_VAT",
        "created_at": "2024-07-05T09:22:25.000000Z",
        "is_recurrent": false,
        "installment_template_id": null,
        "package_length_unit": "month",
      },
      {
        "id": "e7404688-cad5-4560-b0bd-961429152223",
        "package_name_en": "20 hrs",
        "package_name_ar": "٢٠ ساعة",
        "package_hours": 20,
        "package_hours_per_package_length_unit": 20,
        "package_price": 70,
        "package_old_price": 72,
        "package_status": "online",
        "type": "Package",
        "educational_level": "School",
        "package_length": 1,
        "discount": 12,
        "internal_name": "SC 20 hrs online",
        "upfront_payment_discount": 0,
        "package_vat": 0.15,
        "vat_type": "SAUDI_VAT",
        "created_at": "2024-07-05T09:22:25.000000Z",
        "is_recurrent": false,
        "installment_template_id": null,
        "package_length_unit": "month",
        "isCheapest": true
      }
    ],
    "message": "Packages returned successfully",
    "code": 200
  },
  'inPerson':{
    "data": [
      {
        "id": "7dc7c46b-145d-434e-9b73-2351b0184c92",
        "package_name_en": "8 hrs",
        "package_name_ar": "٨ ساعات",
        "package_hours": 8,
        "package_hours_per_package_length_unit": 8,
        "package_price": 170,
        "package_old_price": 0,
        "package_status": "offline",
        "type": "Package",
        "educational_level": "School",
        "package_length": 1,
        "discount": 0,
        "internal_name": "SC 8 hrs offline",
        "upfront_payment_discount": 0,
        "package_vat": 0.15,
        "vat_type": "SAUDI_VAT",
        "created_at": "2024-07-05T09:22:25.000000Z",
        "is_recurrent": false,
        "installment_template_id": null,
        "package_length_unit": "month"
      },
      {
        "id": "ecbd6d33-61d3-4cfc-a715-4679e8bf0817",
        "package_name_en": "12 hrs",
        "package_name_ar": "١٢ ساعة",
        "package_hours": 12,
        "package_hours_per_package_length_unit": 12,
        "package_price": 170 ,
        "package_old_price": 0,
        "package_status": "offline",
        "type": "Package",
        "educational_level": "School",
        "package_length": 1,
        "discount": 0,
        "internal_name": "SC 12 hrs offline",
        "upfront_payment_discount": 0,
        "package_vat": 0.15,
        "vat_type": "SAUDI_VAT",
        "created_at": "2024-07-05T09:22:25.000000Z",
        "is_recurrent": false,
        "installment_template_id": null,
        "package_length_unit": "month",
        "isPreferred": true
      },
      {
        "id": "88c71578-e72a-41ac-8b0f-43a14aec9344",
        "package_name_en": "16 hrs",
        "package_name_ar": "١٦ ساعة",
        "package_hours": 16,
        "package_hours_per_package_length_unit": 16,
        "package_price": 170 ,
        "package_old_price": 0,
        "package_status": "offline",
        "type": "Package",
        "educational_level": "School",
        "package_length": 1,
        "discount": 0,
        "internal_name": "SC 16 hrs offline",
        "upfront_payment_discount": 0,
        "package_vat": 0.15,
        "vat_type": "SAUDI_VAT",
        "created_at": "2024-07-05T09:22:25.000000Z",
        "is_recurrent": false,
        "installment_template_id": null,
        "package_length_unit": "month"
      },
      {
        "id": "f07339ef-9275-4eda-aa2f-d6a6439f07c5",
        "package_name_en": "20 hrs",
        "package_name_ar": "٢٠ ساعة",
        "package_hours": 20,
        "package_hours_per_package_length_unit": 20,
        "package_price": 170 ,
        "package_old_price": 0,
        "package_status": "offline",
        "type": "Package",
        "educational_level": "School",
        "package_length": 1,
        "discount": 0,
        "internal_name": "SC 20 hrs offline",
        "upfront_payment_discount": 0,
        "package_vat": 0.15,
        "vat_type": "SAUDI_VAT",
        "created_at": "2024-07-05T09:22:25.000000Z",
        "is_recurrent": false,
        "installment_template_id": null,
        "package_length_unit": "month"
      }
    ],
    "message": "Packages returned successfully",
    "code": 200
  }
}
