import { Injectable } from '@angular/core';
import {interval, map, Observable, takeWhile} from "rxjs";
import {filter} from "rxjs/operators";
declare var _vwo_code: any;

@Injectable({
  providedIn: 'root'
})
export class VwoService {

  constructor() { }

  finished(): Observable<void> {
    return new Observable<void>((observer) => {
      const observable = interval(1000).pipe(
        takeWhile(() => !_vwo_code.finished())
      ).subscribe({
        complete: () => {
          observer.next();
          observer.complete();
        }
      });
      return () => observable.unsubscribe();
    });
  }
}
