import { Routes } from '@angular/router';

export const signupRoutes: Routes = [
  { path: 'subjects', loadComponent: () => import('./sections').then(m => m.SubjectComponent) },
  { path: 'sub-levels', loadComponent: () => import('./sections').then(m => m.SubLevelsComponent) },
  { path: 'curriculum', loadComponent: () => import('./sections').then(m => m.CurriculumComponent) },
  { path: 'session-type', loadComponent: () => import('./sections').then(m => m.SessionTypeComponent) },
  { path: 'map', loadComponent: () => import('./sections').then(m => m.MapComponent) },
  { path: 'loader', loadComponent: () => import('./sections').then(m => m.LoaderComponent) },
  { path: 'tutor-card', loadComponent: () => import('./sections').then(m => m.TutorCardComponent) },
  { path: 'sign-up', loadComponent: () => import('./sections').then(m => m.SignUpComponent) },
  { path: 'packages', loadComponent: () => import('./sections').then(m => m.PackagesComponent) },
  { path: 'select-package', loadComponent: () => import('./sections').then(m => m.Packagesv2Component) },
  { path: 'result', loadComponent: () => import('./sections').then(m => m.ThankYouComponent) },
  { path: '', redirectTo: 'subjects', pathMatch: 'full' },
];
